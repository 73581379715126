.issueContentPaper {
    width: 100% !important;
    margin: 0 !important;
    min-height: 100vh !important;
    height: 100% !important;
  }

  .alert-container{
    position: fixed;
    width: 265px;
    bottom: 60px;
    left: 15px;
    z-index: 3000;
    height: 300px;
  }

