.filter-form{
    padding: 15px;
    background-color: #f7f7f7;
    margin: 3px;
}
.filter{  
    display: flex;
}

.filter div{
    flex-grow: 1;
    /* flex: 1 1 0px */
}

.filter .MuiButton-root{
    font-size: 12px;
}

.filter .MuiInputBase-root{
    width: 300px;
    margin-bottom: 15px;
    background-color: #fff;
}


.create-manual-order .row{
    display: flex;
}

.create-manual-order .row .col{
    flex-grow: 1;
    padding: 7.5px;
}

.create-manual-order .MuiFormControl-root{
    width: 100%;
}


.MuiDialog-paperWidthSm {
    max-width: 900px !important;
    min-width: 900px !important;
}